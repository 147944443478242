meta.foundation-version {
  font-family: "/5.4.6/";
}

meta.foundation-mq-small {
  font-family: "/only screen/";
  width: 0em;
}

meta.foundation-mq-medium {
  font-family: "/only screen and (min-width:40.063em)/";
  width: 40.063em;
}

meta.foundation-mq-large {
  font-family: "/only screen and (min-width:64.063em)/";
  width: 64.063em;
}

meta.foundation-mq-xlarge {
  font-family: "/only screen and (min-width:90.063em)/";
  width: 90.063em;
}

meta.foundation-mq-xxlarge {
  font-family: "/only screen and (min-width:120.063em)/";
  width: 120.063em;
}

meta.foundation-data-attribute-namespace {
  font-family: false;
}

html,
body {
  height: 100%;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  font-size: 100%;
}

a:hover {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

img {
  -ms-interpolation-mode: bicubic;
}

#map_canvas img,
#map_canvas embed,
#map_canvas object,
.map_canvas img,
.map_canvas embed,
.map_canvas object {
  max-width: none !important;
}

.left {
  float: left !important;
}

.right {
  float: right !important;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

.hide {
  display: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: inline-block;
  vertical-align: middle;
}

.row {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
  /*max-width: 62.5em;*/
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.row.collapse > .column,
.row.collapse > .columns {
  padding-left: 0;
  padding-right: 0;
}
.row.collapse .row {
  margin-left: 0;
  margin-right: 0;
}
.row .row {
  width: auto;
  margin-left: -0.9375em;
  margin-right: -0.9375em;
  margin-top: 0;
  margin-bottom: 0;
  max-width: none;
}
.row .row:before,
.row .row:after {
  content: " ";
  display: table;
}
.row .row:after {
  clear: both;
}
.row .row.collapse {
  width: auto;
  margin: 0;
  max-width: none;
}
.row .row.collapse:before,
.row .row.collapse:after {
  content: " ";
  display: table;
}
.row .row.collapse:after {
  clear: both;
}

.row.content {
  max-width: 2000px;
}

.column,
.columns {
  padding-left: 0;
  padding-right: 0.9375em;
  width: 100%;
  float: left;
}

.column .nopadding,
.columns .nopadding {
  padding-left: 0px;
  padding-right: 0px;
}

[class*="column"] + [class*="column"]:last-child {
  float: right;
}

[class*="column"] + [class*="column"].end {
  float: left;
}

@media only screen {
  .hide-for-small {
    display: none !important;
  }
  .hide-for-medium {
    display: block !important;
  }
  .small-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }

  .small-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }

  .small-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .small-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .small-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .small-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .small-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .small-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .small-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .small-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .small-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .small-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .small-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .small-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .small-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .small-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .small-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .small-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .small-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .small-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .small-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .small-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .small-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .small-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0.9375em;
    float: left;
  }

  .small-1 {
    width: 8.33333%;
  }

  .small-2 {
    width: 16.66667%;
  }

  .small-3 {
    width: 25%;
  }

  .small-4 {
    width: 33.33333%;
  }

  .small-5 {
    width: 41.66667%;
  }

  .small-6 {
    width: 50%;
  }

  .small-7 {
    width: 58.33333%;
  }

  .small-8 {
    width: 66.66667%;
  }

  .small-9 {
    width: 75%;
  }

  .small-10 {
    width: 83.33333%;
    padding-right: 0;
  }

  .small-11 {
    width: 91.66667%;
  }

  .small-12 {
    width: 100%;
  }

  .small-offset-0 {
    margin-left: 0% !important;
  }

  .small-offset-1 {
    margin-left: 8.33333% !important;
  }

  .small-offset-2 {
    margin-left: 16.66667% !important;
  }

  .small-offset-3 {
    margin-left: 25% !important;
  }

  .small-offset-4 {
    margin-left: 33.33333% !important;
  }

  .small-offset-5 {
    margin-left: 41.66667% !important;
  }

  .small-offset-6 {
    margin-left: 50% !important;
  }

  .small-offset-7 {
    margin-left: 58.33333% !important;
  }

  .small-offset-8 {
    margin-left: 66.66667% !important;
  }

  .small-offset-9 {
    margin-left: 75% !important;
  }

  .small-offset-10 {
    margin-left: 83.33333% !important;
  }

  .small-offset-11 {
    margin-left: 91.66667% !important;
  }

  .small-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }

  .column.small-centered,
  .columns.small-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.small-uncentered,
  .columns.small-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.small-centered:last-child,
  .columns.small-centered:last-child {
    float: right;
  }

  .column.small-uncentered:last-child,
  .columns.small-uncentered:last-child {
    float: right;
  }

  .column.small-uncentered.opposite,
  .columns.small-uncentered.opposite {
    float: right;
  }

  .small-padding-top-0 {
    padding-top: 0 !important;
  }
  .small-padding-top-1 {
    padding-top: 12px;
  }
  .small-padding-top-2 {
    padding-top: 24px;
  }
  .small-padding-top-3 {
    padding-top: 36px;
  }
  .small-padding-top-4 {
    padding-top: 48px;
  }
  .small-padding-top-5 {
    padding-top: 60px;
  }
  .small-padding-top-6 {
    padding-top: 72px;
  }
  .small-padding-top-12 {
    padding-top: 144px;
  }
  .small-padding-top-25 {
    padding-top: 300px;
  }

  .small-padding-left-0 {
    padding-left: 0 !important;
  }
  .small-padding-left-1 {
    padding-left: 12px !important;
  }
  .small-padding-left-2 {
    padding-left: 24px !important;
  }
  .small-padding-left-3 {
    padding-left: 36px !important;
  }

  .small-padding-right-0 {
    padding-right: 0 !important;
  }
  .small-padding-right-1 {
    padding-right: 12px !important;
  }
  .small-padding-right-2 {
    padding-right: 24px !important;
  }
  .small-padding-right-3 {
    padding-right: 36px !important;
  }
}

@media only screen and (min-width: 40.063em) {
  #goto-cooperation:hover {
    background-image: none;
  }
  .hide-for-small {
    display: block !important;
  }
  .hide-for-medium {
    display: none !important;
  }
  .medium-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }

  .medium-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }

  .medium-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .medium-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .medium-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .medium-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .medium-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .medium-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .medium-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .medium-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .medium-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .medium-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .medium-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .medium-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .medium-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .medium-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .medium-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .medium-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .medium-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .medium-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .medium-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .medium-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .medium-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .medium-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0.9375em;
    float: left;
  }

  .medium-1 {
    width: 8.33333%;
  }

  .medium-2 {
    width: 16.66667%;
  }

  .medium-3 {
    width: 25%;
  }

  .medium-4 {
    width: 33.33333%;
  }

  .medium-5 {
    width: 41.66667%;
  }

  .medium-6 {
    width: 50%;
  }

  .medium-7 {
    width: 58.33333%;
  }

  .medium-8 {
    width: 66.66667%;
  }

  .medium-9 {
    width: 75%;
  }

  .medium-10 {
    width: 83.33333%;
  }

  .medium-11 {
    width: 91.66667%;
  }

  .medium-12 {
    width: 100%;
  }

  .medium-offset-0 {
    margin-left: 0% !important;
  }

  .medium-offset-1 {
    margin-left: 8.33333% !important;
  }

  .medium-offset-2 {
    margin-left: 16.66667% !important;
  }

  .medium-offset-3 {
    margin-left: 25% !important;
  }

  .medium-offset-4 {
    margin-left: 33.33333% !important;
  }

  .medium-offset-5 {
    margin-left: 41.66667% !important;
  }

  .medium-offset-6 {
    margin-left: 50% !important;
  }

  .medium-offset-7 {
    margin-left: 58.33333% !important;
  }

  .medium-offset-8 {
    margin-left: 66.66667% !important;
  }

  .medium-offset-9 {
    margin-left: 75% !important;
  }

  .medium-offset-10 {
    margin-left: 83.33333% !important;
  }

  .medium-offset-11 {
    margin-left: 91.66667% !important;
  }

  .medium-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }

  .column.medium-centered,
  .columns.medium-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.medium-uncentered,
  .columns.medium-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.medium-centered:last-child,
  .columns.medium-centered:last-child {
    float: right;
  }

  .column.medium-uncentered:last-child,
  .columns.medium-uncentered:last-child {
    float: right;
  }

  .column.medium-uncentered.opposite,
  .columns.medium-uncentered.opposite {
    float: right;
  }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .medium-padding-top-1 {
    padding-top: 12px;
  }
  .medium-padding-top-2 {
    padding-top: 24px;
  }
  .medium-padding-top-3 {
    padding-top: 36px;
  }
  .medium-padding-top-4 {
    padding-top: 48px;
  }
  .medium-padding-top-5 {
    padding-top: 60px;
  }
  .medium-padding-top-6 {
    padding-top: 72px;
  }
  .medium-padding-top-12 {
    padding-top: 144px;
  }
  .medium-padding-top-25 {
    padding-top: 300px;
  }

  .medium-padding-left-0 {
    padding-left: 0 !important;
  }
  .medium-padding-left-1 {
    padding-left: 12px !important;
  }
  .medium-padding-left-2 {
    padding-left: 24px !important;
  }
  .medium-padding-left-3 {
    padding-left: 36px !important;
  }

  .medium-padding-right-0 {
    padding-right: 0 !important;
  }
  .medium-padding-right-1 {
    padding-right: 12px !important;
  }
  .medium-padding-right-2 {
    padding-right: 24px !important;
  }
  .medium-padding-right-3 {
    padding-right: 36px !important;
  }
}

@media only screen and (min-width: 64.063em) {
  #goto-cooperation:hover {
    background-image: none;
  }
  .hide-for-small {
    display: block !important;
  }
  .hide-for-medium {
    display: block !important;
  }
  .large-push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }

  .large-pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }

  .large-push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .large-pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .large-push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .large-pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .large-push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .large-pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .large-push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .large-pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .large-push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .large-pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .large-push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .large-pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .large-push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .large-pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .large-push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .large-pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .large-push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .large-pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .large-push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .large-pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .large-push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .large-pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .column,
  .columns {
    position: relative;
    padding-left: 0;
    padding-right: 0.9375em;
    float: left;
  }

  .large-1 {
    width: 8.33333%;
  }

  .large-1_7 {
    width: 14.2%;
  }

  .large-2 {
    width: 16.66667%;
  }

  .large-3 {
    width: 25%;
  }

  .large-3_4 {
    width: 28.6%;
  }

  .large-4 {
    width: 33.33333%;
  }

  .large-5 {
    width: 41.66667%;
  }

  .large-6 {
    width: 50%;
  }

  .large-7 {
    width: 58.33333%;
  }

  .large-8 {
    width: 66.66667%;
  }

  .large-9 {
    width: 75%;
  }

  .large-10 {
    width: 83.33333%;
  }

  .large-11 {
    width: 91.66667%;
  }

  .large-12 {
    width: 100%;
  }

  .large-offset-0 {
    margin-left: 0% !important;
  }

  .large-offset-1 {
    margin-left: 8.33333% !important;
  }

  .large-offset-2 {
    margin-left: 16.66667% !important;
  }

  .large-offset-3 {
    margin-left: 25% !important;
  }

  .large-offset-4 {
    margin-left: 33.33333% !important;
  }

  .large-offset-5 {
    margin-left: 41.66667% !important;
  }

  .large-offset-6 {
    margin-left: 50% !important;
  }

  .large-offset-7 {
    margin-left: 58.33333% !important;
  }

  .large-offset-8 {
    margin-left: 66.66667% !important;
  }

  .large-offset-9 {
    margin-left: 75% !important;
  }

  .large-offset-10 {
    margin-left: 83.33333% !important;
  }

  .large-offset-11 {
    margin-left: 91.66667% !important;
  }

  .large-reset-order {
    margin-left: 0;
    margin-right: 0;
    left: auto;
    right: auto;
    float: left;
  }

  .column.large-centered,
  .columns.large-centered {
    margin-left: auto;
    margin-right: auto;
    float: none;
  }

  .column.large-uncentered,
  .columns.large-uncentered {
    margin-left: 0;
    margin-right: 0;
    float: left;
  }

  .column.large-centered:last-child,
  .columns.large-centered:last-child {
    float: right;
  }

  .column.large-uncentered:last-child,
  .columns.large-uncentered:last-child {
    float: right;
  }

  .column.large-uncentered.opposite,
  .columns.large-uncentered.opposite {
    float: right;
  }

  .push-0 {
    position: relative;
    left: 0%;
    right: auto;
  }

  .pull-0 {
    position: relative;
    right: 0%;
    left: auto;
  }

  .push-1 {
    position: relative;
    left: 8.33333%;
    right: auto;
  }

  .pull-1 {
    position: relative;
    right: 8.33333%;
    left: auto;
  }

  .push-2 {
    position: relative;
    left: 16.66667%;
    right: auto;
  }

  .pull-2 {
    position: relative;
    right: 16.66667%;
    left: auto;
  }

  .push-3 {
    position: relative;
    left: 25%;
    right: auto;
  }

  .pull-3 {
    position: relative;
    right: 25%;
    left: auto;
  }

  .push-4 {
    position: relative;
    left: 33.33333%;
    right: auto;
  }

  .pull-4 {
    position: relative;
    right: 33.33333%;
    left: auto;
  }

  .push-5 {
    position: relative;
    left: 41.66667%;
    right: auto;
  }

  .pull-5 {
    position: relative;
    right: 41.66667%;
    left: auto;
  }

  .push-6 {
    position: relative;
    left: 50%;
    right: auto;
  }

  .pull-6 {
    position: relative;
    right: 50%;
    left: auto;
  }

  .push-7 {
    position: relative;
    left: 58.33333%;
    right: auto;
  }

  .pull-7 {
    position: relative;
    right: 58.33333%;
    left: auto;
  }

  .push-8 {
    position: relative;
    left: 66.66667%;
    right: auto;
  }

  .pull-8 {
    position: relative;
    right: 66.66667%;
    left: auto;
  }

  .push-9 {
    position: relative;
    left: 75%;
    right: auto;
  }

  .pull-9 {
    position: relative;
    right: 75%;
    left: auto;
  }

  .push-10 {
    position: relative;
    left: 83.33333%;
    right: auto;
  }

  .pull-10 {
    position: relative;
    right: 83.33333%;
    left: auto;
  }

  .push-11 {
    position: relative;
    left: 91.66667%;
    right: auto;
  }

  .pull-11 {
    position: relative;
    right: 91.66667%;
    left: auto;
  }

  .large-padding-top-1 {
    padding-top: 12px;
  }
  .large-padding-top-2 {
    padding-top: 24px;
  }
  .large-padding-top-3 {
    padding-top: 36px;
  }
  .large-padding-top-4 {
    padding-top: 48px;
  }
  .large-padding-top-5 {
    padding-top: 60px;
  }
  .large-padding-top-6 {
    padding-top: 72px;
  }
  .large-padding-top-12 {
    padding-top: 144px;
  }
  .large-padding-top-25 {
    padding-top: 300px;
  }

  .large-padding-left-0 {
    padding-left: 0 !important;
  }
  .large-padding-left-1 {
    padding-left: 12px !important;
  }
  .large-padding-left-2 {
    padding-left: 24px !important;
  }
  .large-padding-left-3 {
    padding-left: 36px !important;
  }

  .large-padding-right-0 {
    padding-right: 0 !important;
  }
  .large-padding-right-1 {
    padding-right: 12px !important;
  }
  .large-padding-right-2 {
    padding-right: 24px !important;
  }
  .large-padding-right-3 {
    padding-right: 36px !important;
  }
}
/* Typography resets */
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}

.subheader {
  line-height: 1.4;
  color: #6f6f6f;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

hr {
  border: solid #dddddd;
  border-width: 1px 0 0;
  clear: both;
  margin: 1.25rem 0 1.1875rem;
  height: 0;
}

/* Helpful Typography Defaults */
em,
i {
  font-style: italic;
  line-height: inherit;
}

strong,
b {
  font-weight: bold;
  line-height: inherit;
}

small {
  font-size: 60%;
  line-height: inherit;
}

code {
  font-family: Consolas, "Liberation Mono", Courier, monospace;
  font-weight: normal;
  color: #333333;
  background-color: #f8f8f8;
  border-width: 1px;
  border-style: solid;
  border-color: #e0e0e0;
  padding: 0.125rem 0.3125rem 0.0625rem;
}

/* Lists */
ul,
ol,
dl {
  margin-bottom: 1.25rem;
  list-style-position: outside;
  font-family: inherit;
}

ul {
  margin-left: 1.1rem;
}
ul.no-bullet {
  margin-left: 0;
}
ul.no-bullet li ul,
ul.no-bullet li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
  list-style: none;
}

/* Unordered Lists */
ul li ul,
ul li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}
ul.square li ul,
ul.circle li ul,
ul.disc li ul {
  list-style: inherit;
}
ul.square {
  list-style-type: square;
  margin-left: 1.1rem;
}
ul.circle {
  list-style-type: circle;
  margin-left: 1.1rem;
}
ul.disc {
  list-style-type: disc;
  margin-left: 1.1rem;
}
ul.no-bullet {
  list-style: none;
}

/* Ordered Lists */
ol {
  margin-left: 1.4rem;
}
ol li ul,
ol li ol {
  margin-left: 1.25rem;
  margin-bottom: 0;
}

/* Definition Lists */
dl dt {
  margin-bottom: 0.3rem;
  font-weight: bold;
}
dl dd {
  margin-bottom: 0.75rem;
}

/* Abbreviations */
abbr,
acronym {
  text-transform: uppercase;
  font-size: 90%;
  color: #222222;
  cursor: help;
}

abbr {
  text-transform: none;
}
abbr[title] {
  border-bottom: 1px dotted #dddddd;
}

/* Microformats */
.vcard {
  display: inline-block;
  margin: 0 0 1.25rem 0;
  border: 1px solid #dddddd;
  padding: 0.625rem 0.75rem;
}
.vcard li {
  margin: 0;
  display: block;
}
.vcard .fn {
  font-weight: bold;
  font-size: 0.9375rem;
}

.vevent .summary {
  font-weight: bold;
}
.vevent abbr {
  cursor: default;
  text-decoration: none;
  font-weight: bold;
  border: none;
  padding: 0 0.0625rem;
}

@media only screen and (min-width: 40.063em) {
  /*h1, h2, h3, h4, h5, h6 {
    line-height: 1.4; }

  h1 {
    font-size: 2.75rem; }

  h2 {
    font-size: 2.3125rem; }

  h3 {
    font-size: 1.6875rem; }

  h4 {
    font-size: 1.4375rem; }

  h5 {
    font-size: 1.125rem; }

  h6 {
    font-size: 1rem; } }*/
  /*
 * Print styles.
 *
 * Inlined to avoid required HTTP connection: www.phpied.com/delay-loading-your-print-css/
 * Credit to Paul Irish and HTML5 Boilerplate (html5boilerplate.com)
*/
  .print-only {
    display: none !important;
  }

  @media print {
    * {
      background: transparent !important;
      color: black !important;
      /* Black prints faster: h5bp.com/s */
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]:after {
      content: " (" attr(href) ")";
    }

    abbr[title]:after {
      content: " (" attr(title) ")";
    }

    .ir a:after,
    a[href^="javascript:"]:after,
    a[href^="#"]:after {
      content: "";
    }

    pre,
    blockquote {
      border: 1px solid #999999;
      page-break-inside: avoid;
    }

    thead {
      display: table-header-group;
      /* h5bp.com/t */
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      width: 100%;
      max-width: 100% !important;
    }

    @page {
      margin: 0.5cm;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }

    .hide-on-print {
      display: none !important;
    }

    .print-only {
      display: block !important;
    }

    .hide-for-print {
      display: none !important;
    }

    .show-for-print {
      display: inherit !important;
    }
  }
  .split.button {
    position: relative;
    padding-right: 5.0625rem;
  }
  .split.button span {
    display: block;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: solid 1px;
  }
  .split.button span:after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-style: inset;
    top: 50%;
    left: 50%;
  }
  .split.button span:active {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .split.button span {
    border-left-color: rgba(255, 255, 255, 0.5);
  }
  .split.button span {
    width: 3.09375rem;
  }
  .split.button span:after {
    border-top-style: solid;
    border-width: 0.375rem;
    top: 48%;
    margin-left: -0.375rem;
  }
  .split.button span:after {
    border-color: white transparent transparent transparent;
  }
  .split.button.secondary span {
    border-left-color: rgba(255, 255, 255, 0.5);
  }
  .split.button.secondary span:after {
    border-color: white transparent transparent transparent;
  }
  .split.button.alert span {
    border-left-color: rgba(255, 255, 255, 0.5);
  }
  .split.button.success span {
    border-left-color: rgba(255, 255, 255, 0.5);
  }
  .split.button.tiny {
    padding-right: 3.75rem;
  }
  .split.button.tiny span {
    width: 2.25rem;
  }
  .split.button.tiny span:after {
    border-top-style: solid;
    border-width: 0.375rem;
    top: 48%;
    margin-left: -0.375rem;
  }
  .split.button.small {
    padding-right: 4.375rem;
  }
  .split.button.small span {
    width: 2.625rem;
  }
  .split.button.small span:after {
    border-top-style: solid;
    border-width: 0.4375rem;
    top: 48%;
    margin-left: -0.375rem;
  }
  .split.button.large {
    padding-right: 5.5rem;
  }
  .split.button.large span {
    width: 3.4375rem;
  }
  .split.button.large span:after {
    border-top-style: solid;
    border-width: 0.3125rem;
    top: 48%;
    margin-left: -0.375rem;
  }
  .split.button.expand {
    padding-left: 2rem;
  }
  .split.button.secondary span:after {
    border-color: #333333 transparent transparent transparent;
  }
  .split.button.radius span {
    -webkit-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
  }
  .split.button.round span {
    -webkit-border-bottom-right-radius: 1000px;
    -webkit-border-top-right-radius: 1000px;
    border-bottom-right-radius: 1000px;
    border-top-right-radius: 1000px;
  }

  .reveal-modal-bg {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    background: rgba(0, 0, 0, 0.45);
    z-index: 1004;
    display: none;
    left: 0;
  }

  .reveal-modal,
  dialog {
    visibility: hidden;
    display: none;
    position: absolute;
    z-index: 1005;
    width: 100vw;
    top: 0;
    border-radius: 3px;
    left: 0;
    background-color: white;
    padding: 1.25rem;
    border: solid 1px #666666;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    padding: 1.875rem;
  }
  @media only screen and (max-width: 40em) {
    .reveal-modal,
    dialog {
      min-height: 100vh;
    }
  }
  .reveal-modal .column,
  dialog .column,
  .reveal-modal .columns,
  dialog .columns {
    min-width: 0;
  }
  .reveal-modal > :first-child,
  dialog > :first-child {
    margin-top: 0;
  }
  .reveal-modal > :last-child,
  dialog > :last-child {
    margin-bottom: 0;
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal,
    dialog {
      width: 80%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal,
    dialog {
      top: 6.25rem;
    }
  }
  .reveal-modal.radius,
  dialog.radius {
    border-radius: 3px;
  }
  .reveal-modal.round,
  dialog.round {
    border-radius: 1000px;
  }
  .reveal-modal.collapse,
  dialog.collapse {
    padding: 0;
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.tiny,
    dialog.tiny {
      width: 30%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.small,
    dialog.small {
      width: 40%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.medium,
    dialog.medium {
      width: 60%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.large,
    dialog.large {
      width: 70%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.xlarge,
    dialog.xlarge {
      width: 95%;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .reveal-modal.full,
  dialog.full {
    top: 0;
    left: 0;
    height: 100%;
    height: 100vh;
    min-height: 100vh;
    margin-left: 0 !important;
  }
  @media only screen and (min-width: 40.063em) {
    .reveal-modal.full,
    dialog.full {
      width: 100vw;
      max-width: 62.5em;
      left: 0;
      right: 0;
      margin: 0 auto;
    }
  }
  .reveal-modal .close-reveal-modal,
  dialog .close-reveal-modal {
    font-size: 2.5rem;
    line-height: 1;
    position: absolute;
    top: 0.5rem;
    right: 0.6875rem;
    color: #aaaaaa;
    font-weight: bold;
    cursor: pointer;
  }

  dialog {
    display: none;
  }
  dialog::backdrop,
  dialog + .backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: black;
    background: rgba(0, 0, 0, 0.45);
    z-index: auto;
    display: none;
    left: 0;
  }
  dialog[open] {
    display: block;
  }

  @media print {
    dialog,
    .reveal-modal,
    dialog {
      display: none;
      background: white !important;
    }
  }
  .label {
    font-weight: normal;
    font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    text-align: center;
    text-decoration: none;
    line-height: 1;
    white-space: nowrap;
    display: inline-block;
    position: relative;
    margin-bottom: inherit;
    padding: 0.25rem 0.5rem 0.25rem;
    font-size: 0.6875rem;
    background-color: #2ba6cb;
    color: white;
  }
  .label.radius {
    border-radius: 3px;
  }
  .label.round {
    border-radius: 1000px;
  }
  .label.alert {
    background-color: #c60f13;
    color: white;
  }
  .label.warning {
    background-color: #f08a24;
    color: white;
  }
  .label.success {
    background-color: #5da423;
    color: white;
  }
  .label.secondary {
    background-color: #e9e9e9;
    color: #333333;
  }
  .label.info {
    background-color: #a0d3e8;
    color: #333333;
  }

  [class*="block-grid-"] {
    display: block;
    padding: 0;
    margin: 0 -0.625rem;
  }
  [class*="block-grid-"]:before,
  [class*="block-grid-"]:after {
    content: " ";
    display: table;
  }
  [class*="block-grid-"]:after {
    clear: both;
  }
  [class*="block-grid-"] > li {
    display: block;
    height: auto;
    float: left;
    padding: 0 0.625rem 1.25rem;
  }

  @media only screen {
    .small-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }
    .small-block-grid-1 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .small-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }
    .small-block-grid-2 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .small-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }
    .small-block-grid-3 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .small-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }
    .small-block-grid-4 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .small-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }
    .small-block-grid-5 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .small-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }
    .small-block-grid-6 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .small-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }
    .small-block-grid-7 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .small-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }
    .small-block-grid-8 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .small-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }
    .small-block-grid-9 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .small-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }
    .small-block-grid-10 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .small-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }
    .small-block-grid-11 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .small-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }
    .small-block-grid-12 > li:nth-of-type(1n) {
      clear: none;
    }
    .small-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }
  @media only screen and (min-width: 40.063em) {
    .medium-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }
    .medium-block-grid-1 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .medium-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }
    .medium-block-grid-2 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .medium-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }
    .medium-block-grid-3 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .medium-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }
    .medium-block-grid-4 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .medium-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }
    .medium-block-grid-5 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .medium-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }
    .medium-block-grid-6 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .medium-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }
    .medium-block-grid-7 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .medium-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }
    .medium-block-grid-8 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .medium-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }
    .medium-block-grid-9 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .medium-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }
    .medium-block-grid-10 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .medium-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }
    .medium-block-grid-11 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .medium-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }
    .medium-block-grid-12 > li:nth-of-type(1n) {
      clear: none;
    }
    .medium-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }
  @media only screen and (min-width: 64.063em) {
    .large-block-grid-1 > li {
      width: 100%;
      list-style: none;
    }
    .large-block-grid-1 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-1 > li:nth-of-type(1n + 1) {
      clear: both;
    }

    .large-block-grid-2 > li {
      width: 50%;
      list-style: none;
    }
    .large-block-grid-2 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-2 > li:nth-of-type(2n + 1) {
      clear: both;
    }

    .large-block-grid-3 > li {
      width: 33.33333%;
      list-style: none;
    }
    .large-block-grid-3 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-3 > li:nth-of-type(3n + 1) {
      clear: both;
    }

    .large-block-grid-4 > li {
      width: 25%;
      list-style: none;
    }
    .large-block-grid-4 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-4 > li:nth-of-type(4n + 1) {
      clear: both;
    }

    .large-block-grid-5 > li {
      width: 20%;
      list-style: none;
    }
    .large-block-grid-5 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-5 > li:nth-of-type(5n + 1) {
      clear: both;
    }

    .large-block-grid-6 > li {
      width: 16.66667%;
      list-style: none;
    }
    .large-block-grid-6 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-6 > li:nth-of-type(6n + 1) {
      clear: both;
    }

    .large-block-grid-7 > li {
      width: 14.28571%;
      list-style: none;
    }
    .large-block-grid-7 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-7 > li:nth-of-type(7n + 1) {
      clear: both;
    }

    .large-block-grid-8 > li {
      width: 12.5%;
      list-style: none;
    }
    .large-block-grid-8 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-8 > li:nth-of-type(8n + 1) {
      clear: both;
    }

    .large-block-grid-9 > li {
      width: 11.11111%;
      list-style: none;
    }
    .large-block-grid-9 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-9 > li:nth-of-type(9n + 1) {
      clear: both;
    }

    .large-block-grid-10 > li {
      width: 10%;
      list-style: none;
    }
    .large-block-grid-10 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-10 > li:nth-of-type(10n + 1) {
      clear: both;
    }

    .large-block-grid-11 > li {
      width: 9.09091%;
      list-style: none;
    }
    .large-block-grid-11 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-11 > li:nth-of-type(11n + 1) {
      clear: both;
    }

    .large-block-grid-12 > li {
      width: 8.33333%;
      list-style: none;
    }
    .large-block-grid-12 > li:nth-of-type(1n) {
      clear: none;
    }
    .large-block-grid-12 > li:nth-of-type(12n + 1) {
      clear: both;
    }
  }
  .keystroke,
  kbd {
    background-color: #ededed;
    border-color: #dddddd;
    color: #222222;
    border-style: solid;
    border-width: 1px;
    margin: 0;
    font-family: "Consolas", "Menlo", "Courier", monospace;
    font-size: inherit;
    padding: 0.125rem 0.25rem 0;
    border-radius: 3px;
  }
}
