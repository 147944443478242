.main {
  padding-top: 80px;
}

header #logo {
  width: 150px;
  height: 50px;
}

.navlang {
  display: none;
}

header {
  display: flex;
}
