#content
  > section.group.bk-search
  > form
  > fieldset.bk-search-group-basic.group.row
  div
  > label.label
  > span.bk-btn-info:hover
  > span {
  display: block;
}
