$blue: #6ba2b2;

.App {
  text-align: center;
}

/* overwrite */

span.translate {
  display: unset !important;
}

.bk-result-images a img {
  width: 100%;
}

.modal-background {
  background-color: rgba(0, 0, 0, 0.7);
}

.modal.is-active {
  display: -ms-flexbox;
  display: flex;
}

.modal-close {
  background-color: #fff;
}

.rc-slider .rc-slider-handle {
  transform: translateX(0) !important;

  &:focus {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  }
}

.isometrie {
  top: 100px !important;
  height: 720px !important;
  padding-right: 50px;
}

/* background-color of labels transparent (ie11 produces blue background-color on label paddings) */

label {
  background-color: rgba(255, 255, 255, 0) !important;
}

img {
  &.zoomed-grundriss {
    width: 10rem !important;
    height: auto !important;
  }

  &.konf-grundriss-non-zoomed {
    height: 497px !important;
    margin: auto !important;
  }

  &.konf-grundriss-zoomed {
    width: 17rem !important;
    height: auto !important;
  }
}

.Toastify__toast--info {
  background-color: $blue;
}

svg {
  &.isometrie-svg {
    padding: unset;
    margin: 0;
    height: unset;
    width: 97%;
    overflow: visible;
  }
}

/* sticky renderings */

figure {
  &.image {
    top: 225px;
    position: sticky;
    position: -webkit-sticky;
  }

  div.fit-container {
    padding-bottom: 61%;

    img.fit {
      -o-object-fit: cover;
      object-fit: cover;
      font-family: "object-fit: cover;";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
